var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(!_vm.state.loaded)?_c('div',{staticClass:"row justify-content-md-center"},[_c('div',{staticClass:"col col-lg-2"}),_c('div',{staticClass:"col-md-auto mt-20"},[_c('rotate-square5')],1),_c('div',{staticClass:"col col-lg-2"})]):_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('form',{staticClass:"form-horizontal",on:{"submit":function($event){$event.preventDefault();return _vm.submit($event)}}},[_c('div',{staticClass:"form-body"},[_c('h3',{staticClass:"box-title"},[_vm._v("Indeks Berkas")]),_c('hr',{staticClass:"m-t-0 m-b-40"}),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-8"},[_c('div',{staticClass:"form-group row"},[_c('label',{class:[
                    'control-label',
                    { 'text-right': !_vm.isMobile },
                    'col-md-3' ]},[_vm._v("Kode Klasifikasi")]),_c('div',{staticClass:"col-md-8"},[_c('treeselect',{attrs:{"options":_vm.indukkode},model:{value:(_vm.payload.kode),callback:function ($$v) {_vm.$set(_vm.payload, "kode", $$v)},expression:"payload.kode"}})],1)])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-8"},[_c('div',{staticClass:"form-group row"},[_c('label',{class:[
                    'control-label',
                    { 'text-right': !_vm.isMobile },
                    'col-md-3' ]},[_vm._v("\n                  Tingkat Perkembangan\n                ")]),_c('div',{staticClass:"col-md-8"},[_c('treeselect',{attrs:{"options":_vm.indukklasifikasi2},model:{value:(_vm.payload.perkembangan),callback:function ($$v) {_vm.$set(_vm.payload, "perkembangan", $$v)},expression:"payload.perkembangan"}})],1)])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-8"},[_c('Input',{attrs:{"name":"aktif","type":"text","label":"Retensi Aktif"},model:{value:(_vm.payload.aktif),callback:function ($$v) {_vm.$set(_vm.payload, "aktif", $$v)},expression:"payload.aktif"}})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-8"},[_c('Input',{attrs:{"name":"inaktif","type":"text","label":"Retensi Inaktif"},model:{value:(_vm.payload.inaktif),callback:function ($$v) {_vm.$set(_vm.payload, "inaktif", $$v)},expression:"payload.inaktif"}})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-8"},[_c('div',{staticClass:"form-group row"},[_c('label',{class:[
                    'control-label',
                    { 'text-right': !_vm.isMobile },
                    'col-md-3' ]},[_vm._v("Keterangan Status")]),_c('div',{staticClass:"col-md-8"},[_c('treeselect',{attrs:{"options":_vm.indukketerangan},model:{value:(_vm.payload.ket_status),callback:function ($$v) {_vm.$set(_vm.payload, "ket_status", $$v)},expression:"payload.ket_status"}})],1)])])])]),_c('hr'),_c('div',{staticClass:"form-actions"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-offset-3 col-md-9"},[_c('button',{staticClass:"btn btn-success",attrs:{"type":"submit","title":"Submit"}},[_vm._v("\n                    Submit")]),_vm._v(" \n                  "),_c('button',{staticClass:"btn btn-inverse",attrs:{"type":"button","title":"Cancel"},on:{"click":function($event){_vm.goBack()}}},[_vm._v("\n                    Cancel\n                  ")])])])]),_c('div',{staticClass:"col-md-6"})])])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }