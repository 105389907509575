<template>
  <div>
    <div class="row justify-content-md-center" v-cloak v-if="!state.loaded">
      <div class="col col-lg-2"></div>
      <div class="col-md-auto mt-20">
        <rotate-square5></rotate-square5>
      </div>
      <div class="col col-lg-2"></div>
    </div>
    <div class="card" v-else>
      <div class="card-body">
        <form @submit.prevent="submit" class="form-horizontal">
          <div class="form-body">
            <h3 class="box-title">Indeks Berkas</h3>
            <hr class="m-t-0 m-b-40" />

            <div class="row">
              <div class="col-md-8">
                <div class="form-group row">
                  <label
                    :class="[
                      'control-label',
                      { 'text-right': !isMobile },
                      'col-md-3',
                    ]"
                    >Kode Klasifikasi</label
                  >
                  <div class="col-md-8">
                    <treeselect v-model="payload.kode" :options="indukkode" />
                  </div>
                </div>
              </div>
            </div>

            <!-- <div class="row">
              <div class="col-md-8">
                <div class="form-group row">
                  <label
                    :class="[
                      'control-label',
                      { 'text-right': !isMobile },
                      'col-md-3',
                    ]"
                  >
                    Klasifikasi Akses
                  </label>
                  <div class="col-md-8">
                    <treeselect
                      v-model="payload.klasifikasi"
                      :options="indukklasifikasi"
                    />
                  </div>
                </div>
              </div>
            </div> -->

            <div class="row">
              <div class="col-md-8">
                <div class="form-group row">
                  <label
                    :class="[
                      'control-label',
                      { 'text-right': !isMobile },
                      'col-md-3',
                    ]"
                  >
                    Tingkat Perkembangan
                  </label>
                  <div class="col-md-8">
                    <treeselect
                      v-model="payload.perkembangan"
                      :options="indukklasifikasi2"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-8">
                <Input
                  v-model="payload.aktif"
                  name="aktif"
                  type="text"
                  label="Retensi Aktif"
                />
              </div>
            </div>

            <div class="row">
              <div class="col-md-8">
                <Input
                  v-model="payload.inaktif"
                  name="inaktif"
                  type="text"
                  label="Retensi Inaktif"
                />
              </div>
            </div>

            <!-- <div class="row">
              <div class="col-md-8">
                <Input
                  v-model="payload.tag"
                  name="tag"
                  required
                  type="text"
                  label="Tag"
                />
              </div>
            </div> -->

            <!-- <div class="row">
              <div class="col-md-12 m-t-10 m-b-10">
                <label :class="['control-label']">Keterangan</label>
                <wysiwyg v-model="myHTML" />
              </div>
            </div> -->

            <!-- <div class="row">
              <div class="col-md-8">
                <Input
                  v-model="payload.ket_status"
                  name="ket_status"
                  type="text"
                  label="Keterangan Status"
                />
              </div>
            </div> -->

            <div class="row">
              <div class="col-md-8">
                <div class="form-group row">
                  <label
                    :class="[
                      'control-label',
                      { 'text-right': !isMobile },
                      'col-md-3',
                    ]"
                    >Keterangan Status</label
                  >
                  <div class="col-md-8">
                    <treeselect
                      v-model="payload.ket_status"
                      :options="indukketerangan"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr />
          <div class="form-actions">
            <div class="row">
              <div class="col-md-6">
                <div class="row">
                  <div class="col-md-offset-3 col-md-9">
                    <button
                      type="submit"
                      class="btn btn-success"
                      title="Submit"
                    >
                      Submit</button
                    >&nbsp;
                    <button
                      type="button"
                      @click="goBack()"
                      class="btn btn-inverse"
                      title="Cancel"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
              <div class="col-md-6"></div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { RotateSquare5 } from "vue-loading-spinner";
import Input from "@/components/input/input";
import Multiselect from "vue-multiselect";
import Upload from "@/components/input/upload.vue";
import axios from "axios";
// import Treeselect from '../../../../components/helpers/treeselect.vue';

export default {
  components: {
    Input,
    RotateSquare5,
    Upload,
    Multiselect,
    // Treeselect
  },
  computed: {
    editMode() {
      return !!this.$route.params.id;
    },
    state() {
      return this.$store.state.daftarfolder;
    },
    optionIndeks() {
      const indeks = JSON.parse(localStorage.getItem("refUnit"));
      const indeksDropdown = [];
      if (indeks) {
        indeks.forEach((value) => {
          indeksDropdown.push({ id: value.id, text: value.name });
        });
      }

      return indeksDropdown;
    },
    isInvalidBahasa() {
      return this.isTouched && this.payload.valueBahasa.length === 0;
    },
    isInvalidIndeks() {
      return this.isTouched && this.payload.valueIndeks.length === 0;
    },
    isMobile() {
      return this.$store.state.isMobile;
    },
  },
  data() {
    return {
      options: [
        "Select option",
        "Disable me!",
        "Reset me!",
        "Admin Dinas Komunikasi dan Informatika ( Kepala Seksi Dinas Komunikasi dan Informatika )",
        "label",
        "searchable",
      ],
      // indukklasifikasi: this.$store.state.daftarfolder.items.treeklasifikasi,

      payload: {
        // nomorfolder: "",
        // namafolder: "",
        // retensi_aktif: "",
        // retensi_inaktif: "",
        kode: [],
        // klasifikasi: [],
        perkembangan: [],
        aktif: "",
        inaktif: "",
        ket_status: [],
        // tag: "",
      },
      myHTML: "",
      myFile: [],
      indukkode: [],
      // indukklasifikasi: [],
      indukklasifikasi2: [],
      indukketerangan: [],
      isTouched: false,
      isDisabled: false,
    };
  },
  async mounted() {
    this.initialize();
    this.treeKode();
    // this.treeKlasifikasi();
    this.treeKlasifikasi2();
    this.treeKeterangan();
  },

  methods: {
    treeKode() {
      axios.get(`/kode_klasifikasi/list_indeks`).then((res) => {
        this.indukkode = res.data.data;
      });
    },

    // treeKlasifikasi() {
    //   axios.get(`/document_urgencies/list_indeks`).then((res) => {
    //     this.indukklasifikasi = res.data.data;
    //   });
    // },

    // treeKlasifikasi() {
    //   axios.get(`/clasificationdoc/get_list_klasifikasi`).then((res) => {
    //     this.indukklasifikasi = res.data.data;
    //   });
    // },
    treeKlasifikasi2() {
      this.indukklasifikasi2 = [
        { id: 1, label: "Asli" },
        { id: 2, label: "Fotokopi" },
        { id: 3, label: "Asli dan Fotokopi" },
      ];
    },
    treeKeterangan() {
      this.indukketerangan = [
        { id: 1, label: "Simpan" },
        { id: 2, label: "Permanen" },
        { id: 3, label: "Musnah" },
      ];
    },
    async initialize() {
      try {
        if (this.editMode) {
          axios
            .get(`/daftarfolderlist/${this.$route.params.id}`)
            .then((response) => {
              const state = {
                loaded: true,
              };
              this.$store.commit("daftarfolderlist/STATE", state);
              this.edit(response.data.data);
            });
        } else {
          this.$store.dispatch("daftarfolder/onAdd");
        }
      } catch (e) {
        this.handleApiError(e);
        this.error = e.message;
      }
    },
    // loadOptions({ callback }) {
    //     var vm = this;
    //     axios
    //     .get('/clasificationdoc/listtree')
    //     .then(function (response) {
    //         var options = parseOptions(response);
    //         vm.indukklasifikasi = options.items; // this is one critical step - make sure you update the options variable bound to your v-treeselect component
    //         callback(); // notify vue-treeselect about data population completion
    //     })
    //     .catch(function (error) {
    //         return 'error';
    //     });
    // },
    edit(data) {
      this.payload = {
        namafolder: data.nama_folder,
        kode: data.kode,
        perkembangan: data.perkembangan,
        // klasifikasi: data.klasifikasi,
        aktif: data.retensi_aktif,
        inaktif: data.retensi_inaktif,
        retensi_aktif: data.retensi_aktif,
        retensi_inaktif: data.retensi_inaktif,
        ket_status: data.description,
        // tag: data.tag,
        // myHTML: data.description,
      };
      // this.myHTML = data.description;
    },
    onTouch() {
      this.isTouched = true;
    },
    onChangeBahasa(value) {
      this.payload.valueBahasa = value;
      if (value.indexOf("Reset me!") !== -1) this.payload.valueBahasa = [];
    },
    onChangeIndeks(value) {
      this.payload.valueIndeks = value;
      if (value.indexOf("Reset me!") !== -1) this.payload.valueIndeks = [];
    },
    onSelect(option) {
      if (option === "Disable me!") this.isDisabled = true;
    },
    goBack() {
      this.$store.dispatch("daftarfolder/onCancel");
    },
    submit() {
      const payload = {
        // nomor_berkas: this.payload.nomorfolder,
        // nama_folder: this.payload.namafolder,
        // retensi_aktif: this.payload.retensi_aktif,
        // retensi_inaktif: this.payload.retensi_inaktif,
        id: this.$route.params.id,
        // klasifikasi: this.payload.klasifikasi,
        kode: this.payload.kode,
        perkembangan: this.payload.perkembangan,
        aktif: this.payload.aktif,
        inaktif: this.payload.inaktif,
        ket_status: this.payload.ket_status,
        // tag: this.payload.tag,
        description: this.myHTML,
      };
      const data = JSON.stringify(payload);
      this.$validator.validateAll().then((success) => {
        if (success) {
          if (this.editMode) {
            this.$store.dispatch("daftarfolder/submitEdit", {
              payload,
            });
          } else {
            this.$store.dispatch("daftarfolder/submitAdd", payload);
          }
        }
      });
    },
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>
.multiselect__tags {
  border: 1px solid #ced4da !important;
}
.invalid .multiselect__tags {
  border-color: #f04124 !important;
  padding: 5px 40px 0 8px !important;
}
.invalid .typo__label {
  color: #f04124;
  font-size: 0.8125rem;
}
.multiselect {
  color: #54667a !important;
}
.multiselect__select {
  height: 41px !important;
}
</style>

